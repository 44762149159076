<template>
  <div class="update-card-box">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="320"
    >
      <v-card dark class="pa-4 card-box">
        <v-card-title class="text-h4 pa-2">
          Edit card
        </v-card-title>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-text-field
            v-model="payment.expired_date"
            v-cardformat:formatCardExpiry
            outlined
            name="expired_date"
            :rules="expiredDateRules"
            :label="'MM / YY*'"
            required
            inputmode="numeric"
          />
          <v-text-field
            v-model="payment.name"
            outlined
            name="name"
            :rules="cardHolderNameRules"
            :label="'Name on Card*'"
            required
          />
          <v-text-field
            v-model="payment.address_zip"
            v-cardformat:restrictNumeric
            outlined
            name="address_zip"
            :rules="zipCodeRules"
            :label="'Zip Code*'"
            required
            inputmode="numeric"
          />
        </v-form>
        <!--暂时停止authorized_card-->
        <!-- <div class="authcard-message">
          To verify your credit card we will try charge it for ${{ try_charge_amount/100 }} and immediately refund it.
        </div> -->
        <v-card-actions class="pa-0 justify-center">
          <v-btn
            class="text-capitalize"
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            class="text-capitalize"
            color="primary"
            :loading="loading"
            @click="updatePayment"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <c-snack-bar ref="csnackbar" />
  </div>
</template>

<script>
import CSnackBar from '@/views/auth/components/snackbar'
import { updatePaymentMethod } from '@/api/user'

export default {
  name: 'UpdateCardDialog',
  components: {
    CSnackBar
  },
  props: {
    callback: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      dialog: false,
      valid: true,
      loading: false,
      payment: {
        address_zip: '',
        name: '',
        expired_date: '',
        exp_year: null,
        exp_month: null
      },
      zipCodeRules: [
        v => {
          if (!v) {
            return 'Zip Code is required'
          }
          if (v.trim().length > 20 || v.trim().length < 2) {
            return 'Zip Code must be valid'
          }
          return true
        }
      ],
      cardHolderNameRules: [
        v => {
          if (!v) {
            return 'Name is required'
          }
          if (v.trim().length > 32 || v.trim().length < 2) {
            return 'Name must be valid'
          }
          return true
        }
      ],
      expiredDateRules: [
        v => !!v || 'Expired Date is required'
      ]
    }
  },
  computed: {
    try_charge_amount() {
      const current_sub = this.$store.state.user.userInfo.subscription
      let try_amount = 100
      if (current_sub) {
        try_amount = current_sub.unit_amount
      }
      return try_amount === 0 ? 100 : try_amount
    }
  },
  methods: {
    setData(card) {
      var exp_month = card.expire.replaceAll(' ', '').split('/')[0]
      var exp_year = card.expire.replaceAll(' ', '').split('/')[1]
      this.payment = {
        id: card.id,
        address_zip: card.address_zip,
        name: card.name,
        expired_date: exp_month + ' / ' + exp_year.replace('20', ''),
        exp_year: exp_year,
        exp_month: exp_month
      }
    },
    updatePayment() {
      if (!this.$refs.form.validate() || this.loading) {
        return
      }
      var exp_month = this.payment.expired_date.replaceAll(' ', '').split('/')[0]
      var exp_year = this.payment.expired_date.replaceAll(' ', '').split('/')[1]
      const data = {
        id: this.payment.id,
        address_zip: this.payment.address_zip,
        name: this.payment.name,
        exp_year: exp_year,
        exp_month: exp_month,
        try_charge_amount: this.try_charge_amount
      }

      this.loading = true
      updatePaymentMethod(data).then(res => {
        this.loading = false
        if (res.code !== 0) {
          this.showNackBar('error', res.message, 5000)
          return
        }
        this.showNackBar('success', 'Success', 2000)
        this.dialog = false
        this.callback()
      }, () => {
        this.loading = false
      }).finally(() => {
        this.loading = false
      })
    },
    showNackBar(color, message, timeout = 5000) {
      this.$refs.csnackbar.show(message, color, timeout)
    }
  }
}
</script>

<style lang="scss" scoped>
.authcard-message{
  padding-bottom: 10px;
}
</style>
