<template>
  <v-container class="max-container">
    <v-card class="pa-3 card-box" flat dark color="black">
      <v-row dense>
        <v-col>
          <div v-show="cards.length===0" class="no-data-text">
            You have not added any payment methods
          </div>
        </v-col>
        <v-col
          v-for="(item, i) in cards"
          :key="i"
          cols="12"
        >
          <v-card
            :color="item.default?'#E040FB':'#8C9EFF'"
            dark
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h5"
                  v-text="'************* '+item.card_number"
                />

                <v-card-subtitle v-text="'Expire '+item.expire" />

                <v-card-actions>
                  <v-btn
                    v-if="item.default"
                    text
                    class="ml-2"
                    rounded
                    small
                    outlined
                    color="#8C9EFF"
                  >
                    default
                  </v-btn>
                  <v-btn
                    v-else
                    class="ml-2"
                    rounded
                    small
                    @click="setDefaultDialog(item)"
                  >
                    Set default
                  </v-btn>
                  <v-btn
                    class="ml-2"
                    rounded
                    small
                    @click="editCard(item)"
                  >
                    <v-icon left small>
                      mdi-pencil
                    </v-icon>
                    Edit
                  </v-btn>
                </v-card-actions>
              </div>

              <v-avatar
                class="ma-5 brand-letter"
                tile
              >
                {{ item.brand }}
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <div class="button-box">
      <v-btn block depressed large class="tile text-capitalize" @click="toAddPayment">Add Payment Method</v-btn>
      <!-- <div v-if="allowCancel" class="text-decoration-underline unsubscribe-text" @click="cancel_sub">
        Cancel Subscription
      </div> -->
    </div>
    <over-lay-loading ref="overlayloading" />
    <over-dialog ref="overdialog" :ok-handle="setDefault" />
    <update-card-dialog ref="updateCardDialog" :callback="getCards" />
  </v-container>
</template>

<script>

import { getPaymentMethods, changeDefaultPayment } from '@/api/user'
import OverLayLoading from './components/overLayLoading'
import OverDialog from './components/overDialog'
import UpdateCardDialog from './components/updateCardDialog'

export default {
  name: 'Cards',
  components: {
    OverDialog,
    OverLayLoading,
    UpdateCardDialog
  },
  data: () => ({
    cards: [],
    active_item: null
  }),
  computed: {
    allowCancel() {
      const sub = this.$store.state.user.userInfo.subscription
      return sub && ['active', 'trial_2', 'trial_3'].includes(sub.status)
    }
  },
  created() {
    this.$nextTick(() => {
      this.getCards()
    })
  },
  methods: {
    toAddPayment() {
      this.$router.push({ name: 'AddPayment' })
    },
    getCards() {
      this.$refs.overlayloading.controlShow(true)
      getPaymentMethods().then(res => {
        this.cards = res.data
        this.cards.sort((a, b) => {
          return a.default === b.default ? 0 : a.default > b.default ? -1 : 1
        })
      }).finally(() => {
        this.$refs.overlayloading.controlShow(false)
      })
    },
    setDefaultDialog(item) {
      this.active_item = item
      this.$refs.overdialog.controlDialog(true, `Are you sure you want to set "${item.card_number}" as the default payment method`, 'Confirm')
    },
    setDefault() {
      changeDefaultPayment({ 'id': this.active_item.id }).then(res => {
        this.getCards()
      }).finally(() => {
        this.$refs.overdialog.controlLoading(false)
        this.$refs.overdialog.controlShowDialog(false)
      })
    },
    editCard(card) {
      this.$refs.updateCardDialog.setData(card)
      this.$refs.updateCardDialog.dialog = true
    },
    cancel_sub() {
      var msg = 'I want to cancel the subscription.'
      if (window.isAndroidWebView()) {
        window.AndroidCallback.onShowConfirmDialog(msg)
        return
      }

      if (window.isWebKit()) {
        window.webkit.messageHandlers.unsubscribe.postMessage(msg)
        return
      }

      window.Intercom('showNewMessage', msg)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__title + .v-card__subtitle {
   margin-top: 0px;
}

div.v-avatar{
    height: unset!important;
    width: unset!important;
    min-width: 40px!important;
}

.button-box{
//   margin-bottom: 10px;
  .v-btn{
    background-image: linear-gradient(45deg,#8C9EFF, #E040FB)!important;
    font-weight: bold;
    letter-spacing: 0.05em;
  }
}

.v-footer{
    border: unset!important;
    background-color: transparent!important;
    text-align: center;
}

.card-box{
    height: 83vh;
    overflow-y: auto;
}

.v-card .v-card__actions {
    padding-top: 0px
}

.brand-letter{
  letter-spacing: 0.08em;
}

.unsubscribe-text{
  margin-top: 10px;
  color: #616161;
  text-align: center;
  cursor: pointer;
}
</style>
